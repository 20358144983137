<template>
  <div
    :class="[isCurrent ? 'border-white/80' : 'border-white/10']"
    class="border flex justify-center items-center rounded-full"
  >
    <div
      :style="{ backgroundImage: `url(${avatarColors})` }"
      class="avatar rounded-full w-full h-full flex justify-center items-center bg-center bg-no-repeat bg-cover"
    ></div>
  </div>
</template>

<script setup>
const props = defineProps({
  image: String,
  profileKey: Number,
  color: String,
  isCurrent: {
    type: Boolean,
    default: () => false,
  },
  isKid: {
    type: Boolean,
    default: () => false,
  },
  textClass: String,
});

const avatarColors = computed(() => {
  if (props.image) {
    let mediaUrl;
    if (process.server) {
      mediaUrl = process.env.IMG_HOST;
    } else {
      mediaUrl = useAppConfig().mediaUrl;
    }
    return `${mediaUrl}${props.image}`;
  }
  const colorImage = new URL(
    `../../assets/pngs/avatars/avatar-${props.color}.png`,
    import.meta.url
  ).href;
  return colorImage;
});
</script>
